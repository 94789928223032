<template>
    <div class="popup active">
        <div class="notice_popup evl_item unit">
            <div @click="$emit('close')" class="close">
                <img src="/images/close.png" alt="닫기"/>
            </div>
            <div class="tit" v-if="this.abilDetail.abilSeq == ''">능력단위 등록</div>
            <div class="tit" v-else>능력단위 수정</div>
            <div class="item_box">
                <div class="label ivl15">중 분 류 명</div><div class="detail">{{pop.midClassNm}}</div>
                <div class="label ivl15">소 분 류 명</div><div class="detail">{{pop.minorClassNm}}</div>
                <div class="label ivl15">세 분 류 명</div><div class="detail">{{pop.detailClassNm}}</div>
                <div class="label ivl16">레 벨</div><div class="detail">{{abilDetail.levelCd}}</div>
                <div class="label">능력단위코드</div><div class="detail">{{abilDetail.abilUnitCd}}</div>
                <div class="label ivl17">능력단위명</div><div class="detail">{{abilDetail.abilUnitNm}}</div>
                <div class="label ivl15">사 용 여 부</div><div class="detail">{{abilDetail.useYn}}</div>
            </div>
            <div class="member" v-if="this.abilDetail.abilSeq == ''">입력하신 내용으로 등록하시겠습니까?</div>
            <div class="member" v-else>입력하신 내용으로 수정하시겠습니까?</div>
            <div class="select-btn">
                <div @click="$emit('close')" class="btn">취소</div>
                <div @click="$emit('close', true)" class="btn">예</div> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : ['param'],
    data() {
        return {
            pop : {},
            abilDetail : this.param.abilDetail,
            abilPopSeq : this.param.abilSeq,
        }
    },

    mounted() {
        //console.log('param', this.param);
        
        this.$.httpPost("/api/main/adm/ncsinout/getAbilUnitPop", { abilPopSeq: this.abilPopSeq })
                .then(res => {
                //console.log("getAbilUnitPop Result", res);
                this.pop = res.data.abilPop;
                // console.log('real?' , this.abilDetail);
            }).catch(this.$.httpErrorCommon);

    },
}
</script>